.voucherCardContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
  margin-top: 20px;
}

.voucherCard {
  margin-bottom: 20px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
  background-color: #fff;
  text-align: center;
  position: relative;

  &:hover {
    transform: translateY(-5px);
  }
}

.cardHeader {
  position: relative;
  text-align: center;
  padding: 10px;
}

.appliedText {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  font-weight: bold;
  color: green;
}

.cardTitle {
  margin: 0;
  font-size: 16px;
  color: #333;
}

.cardBody {
  padding: 15px;
  margin-top: 20px;
}
.cardText {
  font-size: 14px;
  color: #666;
}

/* Close button styling */
.closeButton {
  position: absolute;
  top: 5px;
  right: 10px;
  border: none;
  background: transparent;
  font-size: 16px;
  font-weight: bold;
  color: #888;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: #ff4d4d;
  }
}

.rowGroup {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.checkboxRowGroup {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 15px;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 150px;
  align-items: flex-start;

  label {
    margin-bottom: 5px;
  }

  input,
  select {
    width: 100%;
  }
}

.checkboxInput {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  label {
    margin-bottom: 5px;
  }
}

.formCheckInput {
  margin-top: 5px;
  cursor: pointer;
  border: 1px solid #ccc;
  transition: all 0.2s ease;

  &:checked {
    background-color: #007bff;
    border-color: #007bff;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  }

  &:hover {
    border-color: #0056b3;
  }
}

/* customButton.css */
.custom-link-btn {
  color: black !important;
  text-decoration: underline !important;
  border: none !important;
  box-shadow: none !important;
}

.custom-link-btn:focus,
.custom-link-btn:active {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}
.detailsSection {
  position: relative;
  border: 1px solid #f0f0f0;
  padding: 30px 10px 10px 10px;
  margin-top: 15px;
  border-radius: 4px;
}

/* Copy Button inside the Details Section */
.copyButton {
  position: absolute;
  top: 3px;
  left: 5px;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: color 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
}

.copyButton:hover {
  scale: 1.2;
  transition: scale 0.3s ease;
}

.copyButtonContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.copyButtonLabel {
  margin-top: 4px;
  font-size: 12px;
  color: #888;
}
