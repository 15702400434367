@import '../../styles/colors.scss';
@import '../../styles/form.scss';

.radio {
  border: 1px solid $color-border-base;
  padding: 10px 15px;
  font-size: $font-size-base;
  flex: 1;
}

.selected {
  background-color: $color-primary;
  color: white;
  border-color: white;
}

.radio__group {
  display: flex;
}
