@import '../../styles/base.scss';

.modal-header {
  background-color: #f8f9fa;
}

.modal-title {
  text-align: center;
  width: 100%;
}

.modal-body {
  padding: 20px;
  color: #343a40;
}

.merchant-group {
  margin-bottom: 15px;

  .merchant-name {
    color: lighten($color-dark-bg, 10%);
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
    font-size: 20px;
  }
}

.terminal-container {
  padding: 10px;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: #f8f9fa;

  .sub-merchant-details {
    color: lighten($color-dark-bg, 10%);
    font-weight: bold;
  }

  .terminal-item {
    margin-top: 5px;
    color: #495057;
    font-style: italic;
    display: flex;
    align-items: center;

    .input-number {
      margin-left: 10px;
    }
  }
}

.no-terminals,
.no-terminals-text {
  color: #6c757d;
}

.button,
.close-button {
  background-color: lighten($color-dark-bg, 10%);

  &.button {
    margin-right: 10px;
  }
}
