@import '../../styles/base.scss';

.submerchant_dashboard_root_div {
  padding: 20px;
}

.submerchant_dashboard_div {
  max-width: 100%;
}

.submerchant_summary__root {
  width: 100%;
  min-height: 10px;
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 20px;
  justify-content: space-around;
}

.submerchant_summary {
  margin: 10px;
  flex: 1;
  min-height: 15vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  max-width: 230px;
  padding-top: 2%;
  background-color: $color-primary;
  color: white;
}

.submerchant_summary__value {
  font-size: $font-size-xxxlg;
}

.submerchant_summary__header {
  font-size: $font-size-md;
}

.dashboard_error {
  color: $color-danger;
}

.submerchant_tables__div {
  display: flex;
}

.submerchant_earnings__div {
  flex: 1;
  padding: 10px;
}

.table__header {
  display: flex;
  background-color: $color-primary;
  color: rgb(255, 255, 255);
}

.table__header span {
  flex: 3;
  padding: 20px 20px 10px 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table__header span:nth-child(4) {
  flex: 2;
}

.table__header span:nth-child(5) {
  flex: 4;
}

.table__footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  font-size: large;
}

.table__footer span {
  font-size: $font-size-md;
  cursor: pointer;
}

.table__footer span:hover {
  border-bottom: 1px solid $color-dark-bg;
}

.redemption_error {
  margin-top: 20px;
}
