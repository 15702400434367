@import '../../styles/base.scss';

.root__div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 2rem;
  height: 100%;
  width: 100%;
}

.title {
  font-size: 2.5rem;
  font-weight: 500;
  color: $color-primary;
  text-align: center;
}

.sub_title {
  color: gray;
  text-align: center;
  margin-top: 10px;
  font-size: 1rem;
}

.content_root_div {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.content_div {
  width: 200px;
  height: 200px;
  text-align: center;
  margin: 20px;
}

.content__img {
  margin-top: 10px;
  width: 60px;
  height: 60px;
}

.content__title {
  margin: 20px 0;
  font-size: 1.2rem;
  font-weight: 500;
}
