.sidebarContainer {
  height: 100vh;
  border-right: 1px solid #ddd;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-block: 20px;
}

.sidebarContainer::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.sidebarContainer h5 {

  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.header {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 0 20px;
}

/* Container for the search input */
.searchContainer {
  margin-bottom: 20px;
  padding-inline: 20px;
}

/* Styling for the search input */
.searchInput {
  width: 100%;
  padding: 10px 15px;
  height: 35px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
}

.searchInput:focus {
  border-color: #888;
  outline: none;
}

/* Styling for the "no orders" message */
.noOrders {
  margin-top: 20px;
  padding: 10px 15px;
  font-size: 16px;
  color: #777;
}

/* Pagination styles for the sidebar */
.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  margin-top: 20px;
}

.pagination button {
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid #ccc;
  background: #fff;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s ease, border-color 0.3s ease;
}

.pagination button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination span {
  font-size: 14px;
  color: #333;
}
