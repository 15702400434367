@import '../../styles/base.scss';

.pill {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #dbdbdb;
  color: $color-text-base;
  padding: 5px 15px;
  margin-right: 15px;
  margin-bottom: 10px;
  border-radius: 10px;
  font-weight: 500;
  min-width: 100px;

  &:hover {
    cursor: pointer;
  }

  &:hover:not(.selected) {
    background-color: #bbbbbb;
  }
}

.selected {
  color: $color-white;
  background-color: $color-dark-bg;
}

@media (max-width: 575px) {
  .pill {
    padding: 4px 12px;
    margin-right: 8px;
    margin-bottom: 8px;
  }
}
