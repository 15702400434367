@import '../../styles/base.scss';

.root__div {
  flex: 1;
  width: 100%;
  background-color: $color-dark-bg;
  box-sizing: border-box;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.child__div {
  background-color: $color-white;
  max-width: 700px;
  width: 100%;
  padding: 50px 50px 20px 50px;
  box-shadow: 1px 0px 5px rgba(0, 0, 0, 0.5);
}
.child__div h2 {
  color: black;
  font-size: 1.5rem;
  margin-top: 10px;
  font-weight: 600;
  text-align: center;
}

.logo__img {
  width: 25%;
}

.logo__div {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.forgotpw__sub_title {
  font-size: 20px;
  font-weight: 200;
  margin-bottom: 40px;
}
.navigate_back_div {
  text-align: left;
  margin-left: 10%;
  margin-top: 20px;
}
