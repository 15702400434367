.div_root {
  padding: 4%;
  flex: 1;
}

.uploadSlipRoot {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  justify-content: center;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  width: 100%;
  height: 200px;
}

.buttonGroup {
  display: flex;
  flex-direction: row;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
}

.dropzone__custom {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 150px;
  padding: 20px;
  margin-top: 20px;
  border-width: 2px;
  border-radius: 5px;
  border-style: dashed;
  background-color: #f9f9f9;
  color: #555;
  outline: none;
  transition: border 0.2s ease-in-out;
  border-color: #585f63;
  cursor: pointer;
  text-align: center;
}

.dropzone__custom p {
  margin: 0;
  font-size: 14px;
  text-align: center;
}

.dropzone__custom.active {
  border-color: #007bff;
  background-color: #eef6ff;
}

.dropzone__custom:hover {
  border-color: #0056b3;
}

.dropzone__custom input {
  display: none;
}

.actionsContainer {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
}

.buttonWithCheckbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.actionButtonContainer {
  padding: 4px 10px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
}

.actionButton {
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.actionButton:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.previewButton,
.uploadButton {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}

.previewContainer {
  margin-top: 15px;
  width: 100%;
  border: 1px solid #ddd;
  padding: 10px;
}
.skipOrApproveButton {
  margin-top: 25px;
  width: 100%;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  width: 150px;
  text-align: center;
}

.slipsContainer {
  margin-top: 20px;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.slipsTitle {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.slipsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 15px;
  padding: 10px;
}

.slipCard {
  background-color: #ffffff;
  padding: 15px;
  height: 100px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);

  cursor: pointer;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.slipCard p {
  margin-top: 12px;
}
