@import '../../styles/base.scss';
@import '../../styles/form.scss';

.picker__button {
  border: 1px solid $color-border-base;
  background-color: $color-white;
  width: 100%;
  padding: 10px 15px;
  font-size: $font-size-base;
}
