@import '../../styles/base.scss';

.root__div {
  display: flex;
  box-sizing: border-box;
}

.root__div td {
  padding: 20px;
  flex: 3;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid $color-light-bg;
}
.root__div td:nth-child(5) {
  flex: 2;
}
.root__div td:nth-child(6) {
  flex: 2;
}

.clickable {
  cursor: pointer;
}

.earned_amount_text {
  text-align: right;
  margin-right: 40%;
}
