.div_root {
  padding: 4%;
  flex: 1;
}

.invoiceRoot {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  justify-content: space-around;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  width: 100%;
}

.pageHeader {
  margin-bottom: 20px;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  color: #333;
}

.dropdownContainer {
  margin: 30px 0;
  display: flex;
  justify-content: center;
}

.buttonGroup {
  display: flex;
  flex-direction: row;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
}

.button {
  padding: 5px 15px;
  font-size: 1rem;
  font-weight: 500;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  &:hover {
    background-color: #28a745;
  }
}

.popover {
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  padding: 15px;
}

.invoiceDetailTable {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  width: 100%;
}
