@import '../../styles/base.scss';

.root__div {
  flex: 1;
  width: 100%;
  box-sizing: border-box;
  padding: 30px;
  overflow-y: auto;
}

.table__header {
  display: flex;
  padding: 20px;
  background-color: $color-dark-bg;
  color: $color-white;
}

.table__header span {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selected__offer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.offer__child {
  padding: 10px;
}

.detail__group {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 5px;
  border: 1px solid $color-light-bg;
}

.detail__group__nested {
  flex: 1;
  margin: 5px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid $color-light-bg;
}

.details__horizontal {
  display: flex;
}

.detail__header {
  font-size: medium;
  font-weight: bold;
}

.detail__value {
  font-size: $font-size-lg;
}

.search_area_block {
  width: 37%;
  display: flex;
  flex-direction: column;
  float: right;
  margin-top: 5px;
  margin-left: 63%;
  margin-bottom: 2%;
}

.separator {
  height: 10px;
}

.not_found {
  display: flex;
  height: 10%;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 2%;
}

.loading {
  padding: 5%;
}

.date_picker__input {
  width: 100%;
  height: 40px;
}

.error_msg {
  color: $color-danger;
}

.success_response {
  color: $color-success;
  align-self: center;
}

.load_more {
  color: blue;
  font-size: small;
}

.drawer__header_block {
  font-size: large;
  margin-bottom: 2%;
  margin-left: 35%;
}

.drawer__header {
  flex-direction: row;
  margin-left: 2%;
  font-weight: bold;
}
