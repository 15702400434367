.ReactPasswordStrength {
  font-family: inherit;
  font-size: 0.8rem;
  height: 40px;
  border: none !important;
  display: flex;
  flex: 1;

  .ReactPasswordStrength-input {
    flex: 1;
    font-size: 0.8rem;
    padding: 0 10px;
    font-family: 'Poppins';
    border: none !important;
  }

  .ReactPasswordStrength-strength-desc {
    font-style: normal;
    top: 50%;
    transform: translateY(-50%);
    width: auto;
    font-size: 0.8rem;
  }

  .password-strength-filed {
    border-radius: 0.25rem !important;
    overflow: hidden;
    position: relative !important;
    font-family: inherit !important;
  }

  .ReactPasswordStrength-strength-bar {
    position: absolute;
    bottom: 0;
    top: auto;
    left: 0;
    right: 0;
    height: 3px;
    max-width: 400px;
    box-sizing: border-box;
  }
}
