.root_div {
  padding: 20px;
  overflow: auto;
}

.filter_btn {
  margin-right: 20px;
}

.filter_btn_group {
  display: flex;
  margin: 20px 0;
}

.filter_block_status {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  width: 50%;
  margin-left: 50%;
  font-weight: bold;
}

.button_container {
  display: flex;
  justify-content: flex-end;
}

.button {
  width: 100px;
}

.search_area_block {
  margin-top: 20px;
}

.user_input {
  display: flex;
  justify-content: space-between;
}

.right {
  width: 40%;
  margin-bottom: 1rem;
}

.table_search {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 2%;
}

.input_group {
  margin-bottom: 0;
}

.dualPaginateContainer {
  display: flex;
  justify-content: space-between;
}

.batches,
.pages {
  display: flex;
  align-items: center;
}
