@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500;600;700&display=swap');

/* Font family */
$base-font-family: 'Poppins', sans-serif;

/* Font sizes */
$font-size-base: 0.875em;
$font-size-xxxlg: $font-size-base + 2em;
$font-size-xxlg: $font-size-base + 1em;
$font-size-xlg: $font-size-base + 0.6em;
$font-size-lg: $font-size-base + 0.3em;
$font-size-sm: 0.6em;
$font-size-md: 0.825em;

/* Font Weights */
$extra-light: 200;
$medium: 500;
$semi-bold: 600;
$bold: 700;
