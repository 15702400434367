@import '../../styles/base.scss';

.div_root {
  padding: 4%;
  flex: 1;
}

.placeOrderRoot {
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
}

.form_root {
  margin-top: 3%;
  width: 80%;
  margin-left: 10%;
}

.tab {
  width: 80%;
  margin-left: 10%;
}

.card_footer {
  margin-top: 5%;
  align-items: center;
}

.next_btn {
  float: right;
  width: 45%;
}

.prev_btn {
  float: left;
}

.error_msg {
  color: red;
}

.success_msg {
  color: green;
}

::-webkit-input-placeholder {
  font-size: 12px;
}

:-moz-placeholder {
  /* Firefox 18- */
  font-size: 12px;
}

::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 12px;
}

.form_footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 2%;
  margin-bottom: 2%;
}

.footer_btn {
  width: 45%;
}

.new_category_link {
  color: blue;
}

.new_category_link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.category__list {
  display: flex;
  flex-wrap: wrap;
}

.dropzone__custom {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 150px;
  padding: 20px;
  margin-top: 20px;
  border-width: 2px;
  border-radius: 5px;
  border-style: dashed;
  background-color: #f9f9f9;
  color: #555;
  outline: none;
  transition: border 0.2s ease-in-out;
  border-color: #585f63;
  cursor: pointer;
  text-align: center;
}

.dropzone__custom p {
  margin: 0;
  font-size: 14px;
  text-align: center;
}

.dropzone__custom.active {
  border-color: #007bff;
  background-color: #eef6ff;
}

.dropzone__custom:hover {
  border-color: #0056b3;
}

.dropzone__custom input {
  display: none;
}

.error {
  border-color: #ff1744;
}

.success {
  border-color: #00e676;
}

.btn_block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 200px;
}

.addIconButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.buttonAndText {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.iconButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: #ddd;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.iconButton:hover {
  transform: scale(1.1);
  background-color: #e0e0e0;
}

.icon {
  color: #fff;
  font-size: 20px;
}

.addVoucherLabel {
  font-size: 14px;
  color: #585f63;
  margin-left: 8px;
}

.voucherCount {
  font-size: 14px;
  font-weight: bold;
  color: #4caf50;
  margin-top: 5px;
  text-align: center;
}

.orDivider {
  font-size: 16px;
  font-weight: bold;
  color: #666;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  margin-top: 20px;
}

.boldHr {
  border: 0;
  height: 2px;
  background-color: #333;
  margin: 20px 0;
}
