/* Color Variables */
$color-primary: #210842;
$color-secondary: #f9a83e;
$color-accent: #a78dc0;
$color-light-bg: #f1f1f1;
$color-dark-bg: #2a0e45;

$color-text-base: #312e33;
$color-border-base: #adadad;

$color-success: #4da626;
$color-danger: #e3001c;

$color-white: #ffffff;
