@import '../../styles/base.scss';

.root__div {
  height: 75%;
  display: flex;
  box-sizing: border-box;
  width: 100%;
}

.tableHead {
  background-color: $color-dark-bg;
  color: aliceblue;
}

.table_header {
  font-size: large;
}

.table_filter_search {
  flex-direction: row;
}

.table_filter {
  width: 65%;
  display: flex;
  padding-left: 5%;
}

.table_search {
  width: 35%;
  float: left;
  padding-left: 2%;
}
