@import '../../styles/base.scss';

.root__div {
  box-sizing: border-box;
}

.root_form {
  display: flex;
}

.root_form input {
  width: 100%;
  height: 35px;
  outline-offset: 0px;
  outline: none;
  flex: 1;
  font-size: 0.8rem;
  padding: 0 15px;
  border: solid rgb(199, 199, 199) 0.1rem;
  border-radius: 5% 0 0 5%;
  border-right: none;
}

.button_icon {
  text-align: center;
  margin: 0;
  height: 25px;
}

.button {
  height: 35px;
  width: 35px;
  border-radius: 0% 5% 5% 0%;
  padding: 5px 0;
  background-color: white;
  border: solid rgb(199, 199, 199) 0.1rem;
  border-left: none;
}

.button:focus,
.button:active,
button:hover {
  outline: none !important;
  box-shadow: none;
}
