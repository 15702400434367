@import '../../styles/base.scss';

.root__div {
  padding: 20px;
  margin-top: 3%;
  height: 100%;
  display: flex;
  box-sizing: border-box;
  overflow: auto;
}

.form_root {
  margin-top: 2%;
  padding: 2%;
}

.submitBtn {
  width: 100%;
  margin-top: 1%;
}

.apiStatus {
  width: 80%;
  margin-left: 10%;
  display: flex;
  justify-content: center;
}

.successMsg {
  color: green;
}

.errMsg {
  color: red;
}

.tHead {
  background-color: $color-dark-bg;
  color: aliceblue;
}
