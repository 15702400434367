@import '../../styles/base.scss';

.div_root {
  padding: 4%;
  overflow-y: scroll;
  flex: 1;
}

.form_root {
  margin-top: 3%;
  width: 80%;
  margin-left: 10%;
}

.tab {
  width: 80%;
  margin-left: 10%;
}

.card_footer {
  margin-top: 5%;
  align-items: center;
}

.next_btn {
  float: right;
  width: 45%;
}

.prev_btn {
  float: left;
}

.error_msg {
  color: red;
}

.success_msg {
  color: green;
}

::-webkit-input-placeholder {
  font-size: 12px;
}

:-moz-placeholder {
  /* Firefox 18- */
  font-size: 12px;
}

::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 12px;
}

.form_footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 2%;
  margin-bottom: 2%;
}

.footer_btn {
  width: 45%;
}

.new_category_link {
  color: blue;
}

.new_category_link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.category__list {
  display: flex;
  flex-wrap: wrap;
  /*   margin-bottom: 20px; */
}
