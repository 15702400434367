@import '../../styles/base.scss';

.root_div {
  padding: 20px;
  overflow: auto;
}

.filter_btn {
  margin-right: 20px;
}

.filter_btn_group {
  display: flex;
  margin: 20px 0;
}

.filter_block_status {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  width: 50%;
  margin-left: 50%;
  font-weight: bold;
}

.button_container {
  display: flex;
  justify-content: flex-end;
}

.button {
  width: 100px;
}

.search_area_block {
  margin-top: 20px;
}

.user_input {
  display: flex;
  justify-content: space-between;
}

.right {
  width: 40%;
  margin-bottom: 1rem;
}

.print_button_block {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.table_search {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 2%;
}

.drawer__child {
  padding: 10px;
}

.drawer__div {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.delivery__modes {
  display: flex;
  justify-content: space-between;
}

.detail__group {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 5px;
  border: 1px solid $color-light-bg;
}

.detail__group__nested {
  flex: 1;
  margin: 5px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid $color-light-bg;
  max-width: 550px;
}

.details__horizontal {
  display: flex;
}

.detail__header {
  font-size: $font-size-md;
}

.detail__value {
  font-size: $font-size-lg;
  white-space: normal;
  max-width: 550px;
}

.input_group {
  margin-bottom: 0;
}

.dualPaginateContainer {
  display: flex;
  justify-content: space-between;
}

.batches,
.pages {
  display: flex;
  align-items: center;
}

.search_area {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 20px 0;
  box-sizing: border-box;
}
