@import '../../styles/base.scss';

.root__div {
  height: 100%;
  display: flex;
  box-sizing: border-box;
  padding: 30px;
  flex: 1;
  overflow-y: scroll;
}

.spinner {
  align-items: center;
  justify-content: center;
  display: flex;
  flex: 1;
}

.search_area {
  margin-bottom: 1%;
}

.drawer_table {
  padding: 2%;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  max-height: 100vh;
  overflow-y: auto;
}

.drawer_search_area {
  margin-bottom: 2%;
}

.drawer_table_content {
  height: 85vh;
}

.drawer_table_content_page {
  height: 75vh;
}

.drawer_page_header {
  font-size: large;
  font-weight: bold;
}

.successMessage {
  align-self: center;
  justify-content: center;
  margin-bottom: 1%;
}

.table_search {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2%;
}

.error_msg {
  color: red;
}

.success_msg {
  color: green;
}

.dropdownToggle {
  background: none;
  border: none;
  color: #210842;
  padding: 0 0.75rem;
}

.buttonToolbarInline {
  display: flex;
  gap: 8px;
  position: relative;
}

.countBadge {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: lighten($color-dark-bg, 10%);
  color: white;
  border-radius: 50%;
  padding: 7px 7px;
  font-size: 12px;
  font-weight: bold;
  z-index: 10;
}

/* .dropdownToggle:hover, .btn-primary.dropdown-toggle:focus, .btn-primary:focus {
  border:none;
  background: #210842;
  color: white;
} */
