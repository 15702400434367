.root__div {
  margin-right: 10px;
}

.tsubmerchant_picker__control {
  text-align: end;
  align-items: flex-end;
  height: 34px;
  font-size: 0.8rem;
}
