@import '../../styles/base.scss';

.root__div {
  display: flex;
  padding: 20px;
  border-bottom: 1px solid $color-light-bg;
  box-sizing: border-box;
}

.root__div span {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.clickable {
  cursor: pointer;
}
