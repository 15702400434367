@import '../../styles/base.scss';

.root_div {
  padding: 20px;
  overflow: auto;
}

.filter_btn {
  margin-right: 20px;
}

.filter_btn_group {
  display: flex;
  margin: 20px 0;
}

.filter_block_status {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  width: 50%;
  margin-left: 50%;
  font-weight: bold;
}

.detail__group {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 5px;
  border: 1px solid $color-light-bg;
}

.detail__group__nested {
  flex: 1;
  margin: 5px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid $color-light-bg;
  max-width: 550px;
}

.details__horizontal {
  display: flex;
}

.detail__header {
  font-size: $font-size-md;
}

.detail__value {
  font-size: $font-size-lg;
  white-space: normal;
  max-width: 550px;
}

.search_area_block {
  width: 50%;
  display: flex;
  flex-direction: column;
  float: right;
  margin-top: 5px;
  margin-left: 50%;
}

.edit__icon {
  padding-right: 0.5rem;
  height: 1rem;
}

.edit__icon:hover {
  color: black;
}

.print_button_block {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.search_area {
  display: flex;
  flex-direction: column;
}

.table_search {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 2%;
}

.input_group {
  margin-bottom: 0;
}

.content {
  height: 75vh;
}

.footer {
  margin: 30px;
}

.button-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  max-width: 300px;
}
/* Add a new style for the view transaction button*/

.view-transactions-button {
  color: black;
}

/*Add a new style for the Show transaction details*/
.closeButton {
  position: absolute;
  top: 3px;
  right: 7px;
  background: none;
  border: none;
  font-size: 2.5rem;
  cursor: pointer;
  padding-bottom: 20px;
}

.copy_icon {
  margin: 4px;
}

.pickup__ready__message {
  flex: 1;
  margin: 5px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 2px solid rgb(57, 168, 29);
  max-width: 550px;
  margin-top: 50px;
  border-radius: 10px;
}

.pickup__ready__message_header {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 5px;
  text-align: center;
}
