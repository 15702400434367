@import '../../styles/base.scss';
@import '../../styles/form.scss';

.root__div {
  flex: 1;
  width: 100%;
  background-color: $color-dark-bg;
  box-sizing: border-box;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.child__div {
  background-color: $color-white;
  max-width: 700px;
  width: 100%;
  padding: 50px;
  box-shadow: 1px 0px 5px rgba(0, 0, 0, 0.5);
}

.logo__img {
  width: 50%;
}

.logo__div {
  width: 100%;
  display: flex;
  justify-content: center;
}

.welcome__msg {
  margin-top: 30px;
  display: block;
  font-size: $font-size-lg;
  text-align: center;
}

.field_icon {
  float: right;
  margin-right: 25px;
  margin-top: -30px;
  position: relative;
  z-index: 1;
}

.eye__icon {
  text-align: right;
  align-items: flex-end;
}

.forgotpassword_div {
  text-align: right;
  margin-top: 20px;
}
