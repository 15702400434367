@import '../../styles/base.scss';

.root__div {
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 201;
}
.root__div :hover {
  background: $color-primary;
  border-color: $color-primary;
}
.drawer_navigate_button {
  background: $color-primary;
  border-color: $color-primary;
  width: 3em;
  height: 3em;
}
.drawer_navigate_button :hover {
  background: $color-primary;
  border-color: $color-primary;
}