@import '../../styles/colors.scss';

.list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  margin-bottom: 1rem;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  align-items: center;
  padding: 2rem 1rem;
  background-color: $color-primary;
  color: $color-white;

  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.4) -5px 5px, rgba(0, 0, 0, 0.3) -10px 10px,
    rgba(0, 46, 170, 0.2) -15px 15px, rgba(0, 0, 0, 0.1) -10px 10px,
    rgba(0, 0, 0, 0.05) -0 0;

  label,
  span {
    text-align: center;
  }

  label {
    font-size: 1rem;
    font-weight: 600;
  }

  span {
    font-weight: 700;
    font-size: 2.75rem;
  }

  .decimal {
    font-size: 2rem;
  }
}

@media (min-width: 1300px) {
  .list {
    grid-template-columns: repeat(3, 1fr);
  }

  .card {
    min-width: 200px;

    label {
      font-size: 0.8rem;
    }

    span {
      font-size: 2rem;
    }

    .decimal {
      font-size: 1.25rem;
    }
  }
}

@media (min-width: 1600px) {
  .card {
    label {
      font-size: 1rem;
    }

    span {
      font-size: 2.75rem;
    }

    .decimal {
      font-size: 2rem;
    }
  }
}
