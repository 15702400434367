@import '../../styles/base.scss';

.root__div {
  height: 90%; /* Increased height */
  display: flex;
  box-sizing: border-box;
  width: 100%;
  overflow-x: auto; /* Enables horizontal scroll if needed */
}

.table__heading {
  background-color: $color-dark-bg;
  color: aliceblue;
  white-space: nowrap;
}

.table_header {
  font-size: large;
  padding: 1rem;
  text-align: left;
}

.table_filter_search {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
}

.table_row:hover {
  background-color: lighten($color-dark-bg, 10%);
}

.checked {
  background-color: $color-dark-bg;
  color: aliceblue;
}

.table_filter {
  width: 65%;
  display: flex;
  align-items: center;
  padding-left: 5%;
}

.table_search {
  width: 35%;
  float: left;
  padding-left: 2%;
}

.checkbox {
  margin-right: 0.75rem;
}

.checkbox__column {
  white-space: nowrap;
}

input[type='checkbox']:checked {
  accent-color: $color-dark-bg;
}

.table-container {
  width: 100%;
  overflow-x: auto; /* Horizontal scroll if needed */
}

.table {
  width: 100%;
  border-collapse: collapse;
  height: 100%; /* Makes table take full height of container */
}

.table th,
.table td {
  padding: 0.5rem;
  text-align: left;
  border: 1px solid #ddd;
  min-width: 150px; /* Ensures minimum width for each column */
  white-space: nowrap; /* Prevents wrapping */
}

.table th {
  background-color: $color-dark-bg;
  color: aliceblue;
}

.table td:last-child {
  min-width: 200px; /* Adjust this width to fit terminal details */
}

.table-footer {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
}

.add-sub-merchant-btn {
  background-color: $color-dark-bg;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-sub-merchant-btn:hover {
  background-color: lighten($color-dark-bg, 10%);
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.pagination button {
  background-color: $color-dark-bg;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  margin: 0 0.25rem;
  cursor: pointer;
}

.pagination button:hover {
  background-color: lighten($color-dark-bg, 10%);
}
