@import '../../styles/base.scss';

.root__div {
  flex: 1;
  max-width: 100%;
  box-sizing: border-box;
  padding: 30px;
  overflow-y: auto;
  height: 100vh;
}

.dashboard__div {
  max-width: 100%;
}

.tables__div {
  display: flex;
}

.earnings__div,
.offers__div {
  flex: 1;
}

.table__header {
  display: flex;
  background-color: $color-dark-bg;
  color: $color-white;
}

.table__header span {
  flex: 3;
  padding: 20px 20px 10px 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table__header span:nth-child(4) {
  flex: 2;
}
.table__header span:nth-child(5) {
  flex: 4;
}

.table__footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  padding: 10px 20px;
}

.table__footer span {
  font-size: $font-size-md;
  cursor: pointer;
}

.table__footer span:hover {
  border-bottom: 1px solid $color-dark-bg;
}

@media (max-width: 800px) {
  .root__div {
    padding: 10px;
  }

  .tables__div {
    flex-direction: column;
  }
}
.redemption_error {
  margin-top: 20px;
}

.merchant_dropDown {
  display: flex;
  justify-content: space-around;
  flex: 1;
  flex-direction: column;
  margin-bottom: 2%;
}
