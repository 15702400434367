@import '../../styles/base.scss';

.root__div {
  margin: 0 5px;
  padding: 20px 0px;
  display: flex;
  align-items: center;
}

.root__div span {
  font-size: $font-size-lg;
  font-weight: $semi-bold;
}

.separator {
  border-bottom-width: 1px;
  border-bottom-color: $color-light-bg;
  border-bottom-style: solid;
  flex: 1;
  height: 0px;
}
