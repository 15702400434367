.div_root {
  padding: 4%;
  flex: 1;
}

.voucherRoot {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  justify-content: center;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  width: 100%;
  height: auto;
}

.dropdownContainer {
  display: flex;
  justify-content: center;
}

.buttonGroup {
  display: flex;
  flex-direction: row;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
}

.themeItem {
  display: flex;
  align-items: center;
}

.dropdownToggle {
  display: block;
  margin: 0 auto;
  text-align: center;
  background-color: #fff;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 8px 12px;
  font-size: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s ease;
}

.dropdownToggle:hover {
  background-color: #f8f8f8;
}

.dropdownMenu {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  padding: 10px;
  max-height: 300px;
  overflow-y: auto;
}

.dropdownItem {
  padding: 8px 12px;
  transition: background-color 0.2s ease;
  cursor: pointer;
}

.dropdownItem:hover {
  background-color: #f0f0f0;
}

.dropdownSearch input {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 14px;
}

.dropdownPagination {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  border-top: 1px solid #ccc;
  margin-top: 8px;
}

.customDropdown {
  width: 100%;

  .dropdown-toggle {
    width: 100%;
    text-align: left;
    background-color: #fff;
    border: 1px solid #ced4da;
    color: #495057;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &::after {
      margin-left: 0.5rem;
    }
  }

  .dropdown-menu {
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    border: 1px solid #ced4da;
    border-top: none;
    border-radius: 0 0 4px 4px;
  }

  .dropdownSearch {
    padding: 8px;
    input {
      width: 100%;
      padding: 4px;
      border: 1px solid #ced4da;
      border-radius: 4px;
    }
  }

  .dropdown-item {
    display: flex;
    align-items: center;
    padding: 8px 12px;

    &:hover {
      background-color: #f5f5f5;
    }
  }

  .themeImage {
    width: 60px;
    height: 60px;
    object-fit: cover;
    margin-right: 12px;
    border-radius: 6px;
  }

  .themeRow {
    display: flex;
    align-items: center;
  }

  .themeName {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
  }

  .dropdownPagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
    border-top: 1px solid #ced4da;

    button {
      border: none;
      background: none;
      color: #007bff;
      cursor: pointer;

      &:disabled {
        color: #ccc;
        cursor: not-allowed;
      }
    }
  }
}
