.root_div {
  padding: 20px;
  overflow: auto;
}

.filter_btn {
  margin-right: 20px;
}

.filter_btn_group {
  display: flex;
  margin: 20px 0;
}
