@import '../../styles/base.scss';

.root__div {
  height: 100%;
  display: flex;
  box-sizing: border-box;
  padding-inline: 30px;
  flex-direction: column;
}

.root__div_main {
  height: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
}

.filter_block {
  width: 100%;
  min-height: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 20px;
}

.filter_card {
  width: 15%;
  min-height: 60px;
  align-items: center;
  justify-content: center;
  box-shadow: 3px 3px 3px rgb(185, 183, 183);
  font-weight: bold;
  transition: transform 0.4s;
}

.filter_card_active {
  width: 15%;
  min-height: 60px;
  align-items: center;
  justify-content: center;
  box-shadow: 3px 3px 3px rgb(185, 183, 183);
  font-weight: bold;
  transition: transform 0.4s;
  background-color: darkslategray;
  color: white;
}

.filter_card:hover {
  cursor: pointer;
  transform: scale(1.2);
}

.filter_block_status {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  width: 40%;
  margin-left: 60%;
  font-weight: bold;
}

.search_area_block {
  width: 40%;
  display: flex;
  flex-direction: column;
  float: right;
  margin-top: 5px;
  margin-left: 60%;
}

.search_area {
  display: flex;
  flex-direction: column;
}

.seperator {
  height: 10px;
}

.table_div {
  box-shadow: 1px 1px 1px rgb(185, 183, 183);
  padding: 2%;
}

.table_head {
  box-shadow: 2px 2px 2px rgb(95, 93, 93);
  background-color: darkslategray;
  color: white;
}

.table_row:hover {
  cursor: pointer;
}

.not_found_message {
  align-self: center;
}

.purchase_drawer_root {
  flex: 1;
  white-space: nowrap;
  overflow: scroll;
  text-overflow: ellipsis;
  height: 100%;
}

.drawer_child {
  padding: 10px;
}

.header_group {
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  padding: 20px;
  margin: 10px;
  border: 1px solid $color-light-bg;
  justify-content: space-between;
  align-items: center;
  background-color: darkslategray;
  color: white;
}

.header_title {
  font-size: medium;
}

.header_value {
  font-size: medium;
  padding-left: 20px;
  padding-right: 30px;
}

.details_group {
  margin: 15px;
}

.detail_group_content {
  flex: 1;
  margin: 15px;
  display: flex;
  flex-direction: row;
  padding: 5px;
  justify-content: space-between;
}

.content_header {
  font-weight: bold;
  font-size: medium;
}

.content_value {
  margin-right: 5%;
}
