@import '../../styles//base.scss';
@import '../../styles/form.scss';

.root__div {
  flex: 1;
  display: flex;
  box-sizing: border-box;
  overflow: auto;
}

.header__div {
  background-color: $color-primary;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.header__text {
  font-size: $font-size-xxlg;
  color: $color-light-bg;
  letter-spacing: 2px;
}

.form__div {
  padding: 30px;
  width: 100%;
}

.message__div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.message__text {
  display: block;
  margin-top: 20px;
  font-size: $font-size-lg;
}

.add__form {
  width: 100%;
}

.horizontal__parent {
  display: flex;
}

.horizontal__child {
  flex: 1;
}

.main__error {
  display: block;
  text-align: center;
  color: $color-danger;
  margin-top: 10px;
}

.btn_block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
