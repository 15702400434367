@import '../../styles/base.scss';

.label {
  display: inline-flex;
  align-items: center;
  margin-bottom: 1rem;
  align-self: flex-end;
}

.select {
  margin: 0 0.4rem;
  padding: 0.35rem 0.5rem;
}
