@import '../../styles/base.scss';

.drawer_navigate_button {
  background: $color-primary;
  border-color: $color-primary;
  width: 2.5em;
  height: 2.5em;
  text-align: center;
}

.side__drawer {
  height: 100%;
  width: 400px;
  min-width: 300px;
  z-index: 200;
  transition: transform 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  padding: 30px;
  box-sizing: border-box;
  background: $color-primary;
  overflow-y: auto;
  align-self: start;
  box-shadow: 3px 3px 3px rgb(187, 184, 184);
  user-select: none;

  // Hide scrollbar for IE, Edge and Firefox
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.side__drawer::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.list::after {
  content: '';
  display: block;
  height: 30px;
}

.side__drawer.open {
}

.list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.child__list {
  margin-left: 20px;
}

.title span {
  display: block;
  padding: 10px 10px;
  color: $color-white;
}

.item {
  cursor: pointer;
  display: block;
  padding: 10px 10px;
  margin: 2px 0px;
  letter-spacing: 1px;
  font-size: $font-size-md;
  color: $color-white;
}

.clickable {
  cursor: pointer;
  border-bottom: 2px solid $color-primary;
  transition: border-color 0.2s ease-in-out;
}

.clickable:hover {
  border-bottom: 2px solid $color-white;
}

.selected {
  border-color: $color-white;
}

.close__container {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.flex__div {
  margin-top: 0;
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.flex__div span {
  font-size: 2.5rem;
  text-align: center;
  display: block;
  font-weight: 900;
  letter-spacing: 2px;
  text-shadow: 3px 3px 0 white, -1px -1px 0 white, 1px -1px 0 white,
    -1px 1px 0 white, 1px 1px 0 white;
}

.flex__div .black__text {
  color: initial;
}

.close__button {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-size: 0.7rem;
  cursor: pointer;
  color: white;
  padding: 3px;
  padding-right: 10px;
  transition: border 0.3s ease-in-out;
  border-bottom: 1px solid transparent;
}

.close__button:hover {
  border-bottom: 1px solid white;
}

.close__button span {
  margin-top: -1px;
}

.logo__img {
  margin: 0% 15%;
  width: 50%;
}

@media (max-width: 1000px) {
  .side__drawer {
    position: fixed;
    transform: translateX(-100%);
  }

  .side__drawer.open {
    transform: translateX(0);
  }
}
@media (min-width: 1000px) {
  .side__drawer {
    transform: translateX(0);
  }

  .side__drawer.open {
    position: fixed;
    transform: translateX(-100%);
  }
}
.user_details__div {
  display: flex;
  width: 100%;
  padding: 10px;
  margin: 20px 0;
  background-color: $color-white;
  color: $color-primary;
}
.user_details__p {
  color: $color-primary;
  padding-left: 15px;
  margin: 0;
  word-break: break-word;
  font-weight: 500;
}
.user_details__img_div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.user_details__img {
  height: 40px;
}

.empty_div {
  width: 100%;
  height: 5%;
}

.merchant_dropDown {
  margin-top: 12%;
  color: $color-white;
}

.user_details_p_block {
  align-items: center;
  justify-content: center;
  padding: 2%;
}

.resizer {
  width: 10px;
  cursor: ew-resize;
  background: rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 300;
}
