.stepProgressContainer {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 20px 0;
}

.stepCircleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.stepCircle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ccc; // Default gray for inactive steps
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  z-index: 1;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

@keyframes pop {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

.stepCompleted {
  background-color: green; // Successful step: green circle
}

.stepCurrent {
  background-color: rgb(
    51,
    51,
    51
  ); // Active step (not yet successful): dark circle
  animation: fadeIn 0.5s ease-in-out;
  animation: pop 0.5s ease-in-out;
}

.stepLabel {
  margin-top: 5px;
  font-size: 0.85rem;
  text-align: center;
}

.stepLine {
  flex: 1;
  height: 2px;
  background-color: #ccc;
  margin: 0 8px;
}

.stepLineCompleted {
  background-color: green; // Green line when the preceding step is successful
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.disabled {
  pointer-events: none;
}
