.form__error {
  margin-top: -5px;
  color: #e3001c;
  font-size: 0.825em;
}

.form_div {
  margin: 40px;
}
.loading {
  height: 200px;
}
.button_group {
  display: flex;
}
.button {
  flex: 1;
}
