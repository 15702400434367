@import '../../styles/base.scss';

.merchantDrawer_div {
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 4%;
  margin-right: 4%;
  overflow-y: auto;
}

.table_search {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: space-between;
}

.not_available {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.separator {
  width: 10px;
}
