@import '../../styles//base.scss';

.button__container {
  padding: 5px;
}

.button {
  padding: 10px 15px;
  width: 100%;
  background-color: $color-dark-bg;
  color: $color-white;
}
