@import '../../styles/base.scss';

.welcome_root {
  padding: 5%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 1rem;
}

.card_img {
  padding: 5%;
  width: 50%;
}

.social_root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
}

.social_logo {
  width: 60px;
  height: 60px;
}

.social_logo_root {
  margin-left: 3%;
  flex-direction: column;
  display: flex;
  align-items: center;
}
