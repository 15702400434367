@import '../../styles/base.scss';

.submerchant_picker__p {
  flex: 1;
}

.tsubmerchant_picker__control {
  width: 100%;
  height: 35px;
  font-size: 0.8rem;
}
