@import '../../styles/base.scss';

.root__div {
  padding: 30px 30px 0px 0px;
  display: flex;
  align-items: center;
}

.root__div span {
  font-size: $font-size-xxlg;
  padding-right: 20px;
}

.separator {
  border-bottom-width: 1px;
  border-bottom-color: $color-light-bg;
  border-bottom-style: solid;
  flex: 1;
  height: 0px;
}
