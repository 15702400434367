@import '../../styles/base.scss';

.root__div {
  padding: 20px;
  margin-top: 3%;
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
}

.drawer__div {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.drawer__child {
  padding: 10px;
}

.detail__header {
  font-size: $font-size-md;
}

.detail__value {
  font-size: $font-size-lg;
}

.detail__group__nested {
  flex: 1;
  margin: 5px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid $color-light-bg;
}

.payment__header {
  justify-content: center;
  font-size: large;
}

.payment__header_value {
  flex-direction: row;
  margin-left: 2%;
}

.payment__header_content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 90%;
}

.search_area {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 20px 0;
  box-sizing: border-box;
}

.separator {
  height: 10px;
}

.load_more {
  color: blue;
  font-size: small;
}

.drawer_detail {
  display: flex;
  flex-direction: row;
  padding: 2%;
}

.drawer_detail_content {
  width: 50%;
  align-items: center;
  justify-content: center;
  margin-left: 2%;
}

.download_btn {
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  p {
    padding: 10px 0;
  }
}

.download_error_message {
  color: red;
}
