.root_div {
  padding: 20px;
  overflow: auto;
}

.filter_btn {
  margin-right: 20px;
}

.filter_btn_group {
  display: flex;
  margin: 20px 0;
}

.filter_block_status {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  width: 50%;
  margin-left: 50%;
  font-weight: bold;
}

.search_area_block {
  width: 50%;
  display: flex;
  flex-direction: column;
  float: right;
  margin-top: 5px;
  margin-left: 50%;
}

.edit__icon {
  padding-right: 0.5rem;
  height: 1rem;
}

.edit__icon:hover {
  color: black;
}

.print_button_block {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 5px;
  gap: 10px;
}

.print_button_block .input_group {
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
  gap: 10px;
}

@media (max-width: 1000px) {
  .print_button_block {
    flex-direction: column;
    align-items: center;
  }
  .print_button_block .input_group {
    justify-content: end;
  }
}

.search_area {
  display: flex;
  flex-direction: column;
}

.table_search {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 2%;
}

.input_group {
  margin-bottom: 0;
}

.content {
  height: 75vh;
}

.footer {
  margin: 30px;
}

.button-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  max-width: 300px;
}

.view-transactions-button {
  color: black;
}

.closeButton {
  position: absolute;
  top: 3px;
  right: 7px;
  background: none;
  border: none;
  font-size: 2.5rem;
  cursor: pointer;
  padding-bottom: 20px;
}

.copy_icon {
  margin: 4px;
}
