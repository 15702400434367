.root__div {
  height: 100%;
  display: flex;
  box-sizing: border-box;
  padding: 30px;
  flex-direction: column;
  overflow: auto;
}

.table_div {
  min-height: 50vh;
}

.table {
  width: 100%;
}

.edit_btn {
  float: right;
  margin-right: 2%;
}

.modal_footer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.fieldName {
  font-weight: bold;
}

.error_msg {
  color: red;
}

.success_msg {
  color: green;
}

.modal_footer_msg {
  align-items: center;
  justify-content: center;
  align-self: center;
}

.search_area {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 20px 5px;
}

.footer_btn {
  width: 47%;
  margin-left: 2%;
  margin-top: 1%;
}

.separator {
  height: 10px;
}
