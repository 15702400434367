@import '../../styles/base.scss';

.root__div {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loading__text {
  margin-top: 20px;
}
