.modal_footer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.footer_btn {
  width: 47%;
  margin-left: 2%;
  margin-top: 1%;
  padding-top: 20px;
}

.success_msg {
  color: green;
}

.err_msg {
  color: red;
}

.api_response_block {
  align-items: center;
  justify-content: center;
  display: flex;
  margin-bottom: 1%;
}
