@import 'base';

.form__group {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 5px;
}

.input__group {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

.toggle__container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 10px;
  background-color: transparent;
}

.form__input {
  padding: 10px 15px;
  font-size: $font-size-base;
  border: 1px solid $color-border-base;
}

.form__label {
  display: block;
  font-size: $font-size-md;
}

.form__error {
  font-size: $font-size-md;
  color: $color-danger;
}
