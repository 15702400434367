/* Add a custom z-index for the modal */
.orderDetailsModal {
  z-index: 2000;
  opacity: 1;
}

/* Modal Container Styles */
.orderDetailsModal .modal-content {
  border-radius: 8px;
  overflow: hidden;
}

/* Header Styles */
.orderDetailsModal .modal-header {
  background-color: #007bff; // Blue header
  color: white;
  padding: 1rem;
}

.orderDetailsModal .modal-title {
  font-size: 1.5rem;
  font-weight: bold;
}

/* Modal Body Styles */
.orderDetailsModal .modal-body {
  padding: 1rem;
}

/* Table Wrapper for Responsiveness */
.tableResponsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

/* CSV Table Styles */
.csvTable {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1rem;
}

.csvTable th,
.csvTable td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.csvTable th {
  background-color: #f8f9fa;
  font-weight: bold;
}

.csvTable tr:nth-child(even) {
  background-color: #f2f2f2;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .orderDetailsModal .modal-title {
    font-size: 1.2rem;
  }

  .csvTable th,
  .csvTable td {
    padding: 6px;
  }
}
