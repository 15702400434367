@import '../../styles/base.scss';

.div_root {
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
  align-items: center;
  justify-content: center;
  padding: 3rem;
}

.header_text {
  font-size: large;
}

.form_root {
  margin-top: 2%;
  padding: 2%;
}

.div_addBtn {
  flex: 1;
  max-width: 400px;
}

.div_cancelBtn {
  float: left;
  margin-top: 3%;
  margin-bottom: 3%;
}

.loadMore_text {
  font-size: small;
  color: blue;
}

.drawer_div {
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 4%;
  margin-right: 4%;
  min-height: 300px;
}

.spinner {
  margin: 2%;
}

.err {
  color: red;
}

.success {
  color: green;
}

.table_filter {
  width: 100%;
}

.not_available {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-top: 1%;
}

.merchantDrawer_div {
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 4%;
  margin-right: 4%;
}

.card {
  width: 100%;
}

.amount_payable_div {
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
  padding: 2%;
  margin-bottom: 2%;
}

.amount_payable {
  font-size: large;
  font-weight: bold;
}

.amount_payable_line01 {
  font-size: medium;
  padding-bottom: 10px;
}

.table_search {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: space-between;
}

.bottomBtn {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  padding-top: 1rem;
}

.div_payment_details {
  display: flex;
  justify-content: space-between;
  gap: 1rem;

  > div {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
  }
}
