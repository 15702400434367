.cardContainer {
  display: flex;
  align-items: center;
  padding: 8px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05);
  border: 0.2px #dfdcdc;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  padding-inline: 20px;
}

.active {
  background-color: #cfcfcf;
}

.cardImage {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 4px;
  margin-right: 10px;
}

.cardContent {
  display: flex;
  flex-direction: column;
  width: fit-content;
}

.orderCode {
  font-size: 14px;
  font-weight: 700;
  color: #333;
  width: fit-content;
}

.brandName {
  font-size: 13px;
  font-weight: 600;
  color: #5e5e5e;
  border-radius: 8px;
  width: fit-content;
}

.statusBadge {
  font-size: 12px;
  color: #5e5e5e;
  padding: 0 4px;
  border-radius: 8px;
  width: fit-content;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
