@import '../../styles/base.scss';

.main_container {
  display: flex;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.main_container::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.right_container {
  display: flex;
  min-height: 100vh;
  overflow-y: scroll;
  flex: 1;
}

.left_container {
  width: 300px;
  min-width: 250px;
  border-right: 1px solid #ddd;
}

.mainContent {
  flex-grow: 1;
  padding: 20px;
}
