.dropzone__custom {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  border-color: #585f63;
}

.error {
  border-color: #ff1744;
}

.success {
  border-color: #00e676;
}

.download-template {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
}
